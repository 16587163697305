
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const Quote = styled('blockquote')(`
width: calc(100% - 150px);
margin:50px auto;
font-style:italic;
color: #555555;
padding:3.2em 18px 1.2em 24px;
border-left:8px solid #78C0A8 ;
line-height:1.6;
position: relative;
background:#EDEDED;

&:before {
    font-family: Arial;
    content: "\\201C";
    color:#78C0A8;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

&:after {
    content: '';

}

span {
    display: block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}

`)

interface BlockqouteProps {
    progress?: number;
}


export const Blockquote = (props: PropsWithChildren<BlockqouteProps>) => {

    const {children, progress = 0} = props;

    return (
        <Box py={2}
            sx={{
                height: 1,
                display: 'flex',
                alignItems: 'center'
            }}
        >

            <Quote>
                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: 45, marginRight: `${100 - progress}%` , background: 'rgba(255, 255, 255, 0.5)', zIndex: 9 }}>
                    {/* <LinearProgress variant="determinate" value={progress} /> */}
                </Box>

                {children}
            </Quote>
        </Box>

    )
}