import { faHeart, faSquareCheck } from "@fortawesome/free-regular-svg-icons"
import { faAward, faHome, faMoneyCheck, faPeopleGroup, faPeopleRoof, faPerson, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardContent, CardHeader, Container, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon, Typography, useTheme } from "@mui/material"

export const PracticalInformationPage = () => {

    const theme = useTheme();

    return (
        <Container sx={{ pt: 4, pb: 5 }}>

            <Card>

                <CardHeader
                    title={'Praktiske oplysninger'}
                />


                <CardContent>

                    <List>

                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faHome} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Jeg har privatpraksis i Godthåb, 9230 Svenstrup.
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faPerson} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Individuel terapi  1 time
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faHeart} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Parterapi 1½ time
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faPeopleRoof} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Familierådgivning 1½ time
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faPeopleGroup} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Supervision Gruppe / Individuel efter nærmere aftale
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faMoneyCheck} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Pris oplyses ved henvendelse på tlf.6089 4803
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <FontAwesomeIcon color={theme.palette.text.secondary} icon={faAward} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText>
                                Medlem af Dansk Psykoterapeutforening og EMDR Danmark
                            </ListItemText>
                        </ListItem>

                    </List>

                    <Stack spacing={2}>

                        {/* <Typography>
                            Jeg har privatpraksis i Godthåb, 9230 Svenstrup.
                        </Typography>

                        <Typography>
                            Individuel terapi  1 time
                        </Typography>

                        <Typography>
                            Parterapi 1½ time
                        </Typography>

                        <Typography>
                            Familierådgivning 1½ time
                        </Typography>

                        <Typography>
                            Supervision Gruppe / Individuel efter nærmere aftale
                        </Typography>

                        <Typography>
                            Pris oplyses ved henvendelse på tlf.6089 4803
                        </Typography>

                        <Typography>
                            Jeg er medlem af Dansk Psykoterapeutforening
                        </Typography> */}



                    </Stack>

                </CardContent>

            </Card>













        </Container>
    )
}