import Carousel from "react-material-ui-carousel"
import { Blockquote } from "../../common/components/blockquote"
import { QuoteFromCasper } from "../welcome/quotes/casper"
import { QuoteFromJakob } from "../welcome/quotes/jakob"
import { QuoteFromThilde } from "../welcome/quotes/thilde"
import React, { useState } from "react"
import { Box, LinearProgress } from "@mui/material"

export const QuoteCarouselMd = () => {

    const [progress, setProgress] = React.useState(0);

    const [index, setIndex] = useState<number | undefined>(0);

    const timerInterval = 100;

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((oldProgress) => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = (8000 / timerInterval) / 100;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, timerInterval);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, [index]);

    const interval = 8000;

    return (
        <Box position='relative'>
        
            <Carousel
                navButtonsAlwaysVisible
                fullHeightHover
                height={450}
                interval={interval}
                onChange={(now) => setIndex(now)}
                sx={{
                    display: { xs: 'none', md: 'block' }
                }}
            >

                <Blockquote progress={index === 0 ? progress : 0}>
                    <QuoteFromThilde />
                </Blockquote>

                <Blockquote progress={index === 1 ? progress : 0}>
                    <QuoteFromCasper />
                </Blockquote>

                <Blockquote progress={index === 2 ? progress : 0}>
                    <QuoteFromJakob />
                </Blockquote>

            </Carousel>



        </Box>
    )
}