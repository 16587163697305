import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Card, CardContent, CardHeader, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon, Typography, useTheme } from "@mui/material"

export const AboutPageMd = () => {


    const theme = useTheme();


    return (
        <Container sx={{ pt: 4, pb: 10, display: { xs: 'none', md: 'flex' } }} >


            <Grid container spacing={2}>


                <Grid item xs={6} >

                    <Card elevation={0}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ height: 150, width: 150 }}>
                                    <img src={'https://res.cloudinary.com/djlg9t2zf/image/upload/v1742569490/betina_klysner.png'} />
                                </Avatar>
                            }
                            title="Psykoterapeut"
                            subheader="Betina Klysner"
                        />

                        <CardContent>

                            <Stack spacing={2}>

                                <Typography>
                                    Jeg er en erfaren socialrådgiver og certificeret psykoterapeut mpf.
                                </Typography>

                                <Typography>
                                    Igennem mere end 30 år har jeg hjulpet mennesker som har haft psykosociale belastninger og mistrivsel.
                                </Typography>

                                <Typography>
                                    I hele mit arbejdsliv har det været centralt for mig at hjælpe andre til trivsel og til at hjælpe dem med at opnå samhørighed med sig selv og til at finde deres vej til et godt liv.
                                </Typography>

                                <Typography>
                                    Igennem alle år har jeg stillet krav til mig selv om personlig og faglig udvikling, så jeg kan udføre min profession til bedste for andre og for mig selv. Dette er sket gennem videreuddannelse, supervision og kurser.
                                </Typography>

                            </Stack>

                        </CardContent>

                    </Card>


                </Grid>


                <Grid item xs={5}>

                    <Stack spacing={2}>

                        <Card>
                            <CardHeader title="Arbejdserfaring" />
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon>
                                                <FontAwesomeIcon color={theme.palette.text.secondary} icon={faSquareCheck} />
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Psykoterapi
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon>
                                                <FontAwesomeIcon color={theme.palette.text.secondary} icon={faSquareCheck} />
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Misbrugsbehandling
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon>
                                                <FontAwesomeIcon color={theme.palette.text.secondary} icon={faSquareCheck} />
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Rådgivning til unge
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon>
                                                <FontAwesomeIcon color={theme.palette.text.secondary} icon={faSquareCheck} />
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Aktivering af kontanthjælpsmodtagere
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SvgIcon>
                                                <FontAwesomeIcon color={theme.palette.text.secondary} icon={faSquareCheck} />
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Børne-familiearbejde i kommunalt forvaltningsregi
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>


                        <Card>
                            <CardHeader title="Uddannelse" />
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={'Socialrådgiver'} secondary={'1988-1992 Aalborg Universitet'} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={'Supervisor'} secondary={'1997-1998 Diakonhjemmets Høgskolesenter Oslo'} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={'Individual og parterapeut i Gestaltanalyse'} secondary={'2005-2011 Institut for Gestaltanalyse'} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={'Intensiv Dynamisk Korttidsterapi'} secondary={'2010-2013 Institut for Gestaltanalyse'} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={'Spædbarnsterapeut'} secondary={'2019-2020 Institut for Spædbarnsterapi'} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={'EMDR Terapeut Akkrediteret'} secondary={'2021-2024 EMDR Danmark'} />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>

                    </Stack>


                </Grid>


            </Grid>


        </Container >


    )
}